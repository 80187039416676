<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from '../../../helpers/errorHandler';
import FlipCountdown from 'vue2-flip-countdown';
import moment from "moment";

/**
 * Starter component
 */
export default {
  page: {
    title: "Quiz",
  },
  data() {
    return {
      title: "Quiz",
      items: [
        {
          text: "Work",
          href: "/home",
        },
        {
          text: "Quizzes",
          href: "/quizes-log",
        },
        {
          text: "Quiz",
          active: true,
        },
      ],
      form:{
        quiz_id: this.$route.params.quiz,
        answers:[],
      },
      questions:[],
      baseUrl:null,
      deadTime: null,
      type:null,
      submitted: false,
      loading: false
    };
  },
  components: {
    Layout,
    PageHeader,
    FlipCountdown,
  },
  methods:{
    async handleSubmit() {
      this.loading = true;
      this.submitted = true;
      // requests for the create
      if(this.form.answers.findIndex(element => element.answer == "") >= 0){
        errorHandler.methods.errorValue('Please answer all questions!')
        this.loading= false;
        return;
      }
      this.$store.dispatch("quiz/answerQuiz",this.form).then(
        async ()=>{
          await errorHandler.methods.successful('You have solved the Quiz !!');
          this.loading = false;
          this.$router.push('/students/scores');
        }
      ).catch((err)=>{
        errorHandler.methods.errorMessage(err);
        this.submitted= false;
        this.loading= false;
      });
    },
    finish(){
      // this.$store.dispatch("quiz/answerQuiz",{failure:true,...this.form}).then(
      //   ()=>{
      //       this.$router.push('/students/scores');
      //   }
      // ).catch((err)=>{
      //     errorHandler.methods.errorMessage(err);
      //     this.$router.go(-1);
      // });
    }
  },
  async destroyed(){
    if(!this.submitted){
      await this.finish();
    }
  },
  async created(){
    // await window.addEventListener('beforeunload', function(event) {
    //      event.returnValue = 'Your score will be saved as ZERO';
    // })
    // await window.addEventListener('unload', this.finish())
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    await this.$store.dispatch('quiz/getStudentQuiz',this.$route.params.quiz).then( async (res)=>{
        this.questions = res.quiz_questions;
        await this.questions.forEach(element => {
          this.form.answers.push({
            question_id: element.id,
            answer: ""
          })
        });
        this.type = res.quiz_meta_data.quiz_type;
        if(res.quiz_meta_data.quiz_type == 'quiz'){
          if(res.quiz_meta_data.year_id.number == 3){
            this.deadTime = await moment(new Date()).add(25,'minutes').format("YYYY-MM-DD HH:mm:ss").toString()
          }else{
            this.deadTime = await moment(new Date()).add(15,'minutes').format("YYYY-MM-DD HH:mm:ss").toString()
          }
        }else if(res.quiz_meta_data.quiz_type == 'exam'){
          this.deadTime = await moment(new Date()).add(25,'minutes').format("YYYY-MM-DD HH:mm:ss").toString()
        }else{
          this.deadTime = await moment(new Date()).add(90,'minutes').format("YYYY-MM-DD HH:mm:ss").toString()
        }
    }).catch( err => {
        errorHandler.methods.errorMessage(err);
        this.$router.go(-1);
    });
    
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="this.type">
      <div class="col-xl-4 col-lg-4 col-md-12 card">
        <div class="card-body">
          <h2 class="card-title text-center"><strong class="text-danger">IMPORTANT NOTE:</strong><br>If you <br> - <span class="text-danger">Exit</span> the {{ type.toUpperCase() }}<br> OR <br> - <span class="text-danger">Close</span> {{ type.toUpperCase() }} tab <br> Your score will be saved as <span class="text-danger">ZERO</span></h2>
          <flip-countdown v-if="deadTime" :deadline="deadTime" @timeElapsed="finish" :showDays="false" :showHours="(this.type == 'trial')?true:false"></flip-countdown>
        </div>
      </div>
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2>Start {{ type.toUpperCase() }}</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="handleSubmit">
                <div class="row">
                  <div class="col-12">
                    <b-form-group
                        id="input-group-2"
                        :label="`Question ${index+1}:`"
                        label-for="input-A"
                        v-for=" question,index in questions"
                        :key="question.id"
                    >
                    <img style="width: 300px; height: 300px;" v-if="question.question_img" :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'quiz_questions'}/${question.question_img}`">
                        <h3 v-html="question.question_text" class="question">
                        </h3>
                        <div class="row">
                            <div class="col-12" v-if="question.ans_one  || question.ans_one_img">
                                <img style="width: 300px; height: 300px;" v-if="question.ans_one_img" :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'quiz_questions'}/${question.ans_one_img}`">
                                <b-form-radio v-model="form.answers[index].answer" :name="`some-radios-${index}`" value="ans_one"><div v-html="question.ans_one"></div></b-form-radio>
                            </div>
                            <div class="col-12" v-if="question.ans_two  || question.ans_two_img">
                                <img style="width: 300px; height: 300px;" v-if="question.ans_two_img" :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'quiz_questions'}/${question.ans_two_img}`">
                                <b-form-radio  v-model="form.answers[index].answer" :name="`some-radios-${index}`" value="ans_two"><div v-html="question.ans_two"></div></b-form-radio>
                            </div>
                            <div class="col-12" v-if="question.ans_three  || question.ans_three_img">
                                <img style="width: 300px; height: 300px;" v-if="question.ans_three_img" :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'quiz_questions'}/${question.ans_three_img}`">
                                <b-form-radio v-model="form.answers[index].answer" :name="`some-radios-${index}`" value="ans_three"><div v-html="question.ans_three"></div></b-form-radio>
                            </div>
                            <div class="col-12" v-if="question.ans_four  || question.ans_four_img"> 
                                <img style="width: 300px; height: 300px;" v-if="question.ans_four_img" :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'quiz_questions'}/${question.ans_four_img}`">
                                <b-form-radio v-model="form.answers[index].answer" :name="`some-radios-${index}`" value="ans_four"><div v-html="question.ans_four"></div></b-form-radio>
                            </div>
                            <div class="col-12" v-if="question.ans_five  || question.ans_five_img">
                                <img style="width: 300px; height: 300px;" v-if="question.ans_five_img" :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'quiz_questions'}/${question.ans_five_img}`">
                                <b-form-radio v-model="form.answers[index].answer" :name="`some-radios-${index}`" value="ans_five"><div v-html="question.ans_five"></div></b-form-radio>
                            </div>
                        </div>
                        <hr>
                    </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" :disabled="loading">Submit</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="()=>{$router.go(-1);this.finish()}"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
.question::v-deep img {
  max-height: 320px !important;
  max-width: 320px !important;
}
</style>